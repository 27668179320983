import AcquirerStoreTable from "../../pages/acquirerStore/AcquirerStoreTable";
import AcquirerTable from "../../pages/acquirer/AcquirerTable";
import AddressTable from "../../pages/address/AddressTable";
import ApiConfigTable from "../../pages/apiConfig/ApiConfigTable";
import BankDetailTable from "../../pages/bankDetail/BankDetailTable";
import BankJobTable from "../../pages/bankJob/BankJobTable";
import BusinessSettlementSummaryTable from "../../pages/businessSettlementSummary/BusinessSettlementSummaryTable";
import BusinessTable from "../../pages/business/BusinessTable";
import ChargeRateTable from "../../pages/chargeRate/ChargeRateTable";
import Charts from "../../pages/charts";
import Dashboard from "../../pages/dashboard";
import DenormalizedSettlementTransactionTable from "../../pages/denormalizedSettlementTransaction/DenormalizedSettlementTransactionTable";
import DeviceTokenTable from "../../pages/deviceToken/DeviceTokenTable";
import DisplayMessageTable from "../../pages/displayMessage/DisplayMessageTable";
import EmailJobTable from "../../pages/emailJob/EmailJobTable";
import Header from "../Header";
import Icon from "@mdi/react";
import Icons from "../../pages/icons";
import ItemDetailTable from "../../pages/itemDetail/ItemDetailTable";
import Maps from "../../pages/maps";
import NegativeBalanceJobTable from "../../pages/negativeBalanceJob/NegativeBalanceJobTable";
import BankCode from "../../pages/bankCode/BankCodeTable";

import TransactionMonitorDataTable from "../../pages/transactionMonitorData/TransactionMonitorDataTable";

import Notifications from "../../pages/notifications";
import OneTimePasswordTable from "../../pages/oneTimePassword/OneTimePasswordTable";
import React from "react";
import RefundTable from "../../pages/refund/RefundTable";
import RegionTable from "../../pages/region/RegionTable";
import RoleAccessTable from "../../pages/roleAccess/RoleAccessTable";
import SettlementConfigTable from "../../pages/settlementConfig/SettlementConfigTable";
import SettlementRecordTable from "../../pages/settlementRecord/SettlementRecordTable";
import SettlementTimeRecordTable from "../../pages/settlementTimeRecord/SettlementTimeRecordTable";
import Sidebar from "../Sidebar";
import StoreAcquirerSettlementSummaryTable from "../../pages/storeAcquirerSettlementSummary/StoreAcquirerSettlementSummaryTable";
import StoreSettlementSummaryTable from "../../pages/storeSettlementSummary/StoreSettlementSummaryTable";
import StoreTable from "../../pages/store/StoreTable";
import Tables from "../../pages/tables";
import TerminalTable from "../../pages/terminal/TerminalTable";
import TransactionTable from "../../pages/transaction/TransactionTable";
import Typography from "../../pages/typography";
import UserBusinessAccessTable from "../../pages/userBusinessAccess/UserBusinessAccessTable";
import UserRoleAccessTable from "../../pages/userRoleAccess/UserRoleAccessTable";
import UserStoreAccessTable from "../../pages/userStoreAccess/UserStoreAccessTable";
import UserTable from "../../pages/user/UserTable";
import AcquireStoreSettlementDetailTable from "../../pages/acquireStoreSettlementDetail/AcquireStoreSettlementDetailTable";
import classnames from "classnames";
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";
import { Box, IconButton, Link } from "@material-ui/core";

import { Route, Switch, Redirect, withRouter } from "react-router-dom";

// icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles

// components

// pages
// context

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />

            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />

            <Route
              exact
              path="/app/user"
              render={() => <Redirect to="/app/user/table" />}
            />
            <Route path="/app/user/table" component={UserTable} />

            <Route
              exact
              path="/app/business"
              render={() => <Redirect to="/app/business/table" />}
            />

            <Route path="/app/business/table" component={BusinessTable} />

            <Route
              exact
              path="/app/region"
              render={() => <Redirect to="/app/region/table" />}
            />

            <Route path="/app/region/table" component={RegionTable} />

            <Route
              exact
              path="/app/address"
              render={() => <Redirect to="/app/address/table" />}
            />

            <Route path="/app/address/table" component={AddressTable} />

            <Route
              exact
              path="/app/bankDetail"
              render={() => <Redirect to="/app/bankDetail/table" />}
            />

            <Route path="/app/bankDetail/table" component={BankDetailTable} />

            <Route
              exact
              path="/app/userBusinessAccess"
              render={() => <Redirect to="/app/userBusinessAccess/table" />}
            />

            <Route
              path="/app/userBusinessAccess/table"
              component={UserBusinessAccessTable}
            />

            <Route
              exact
              path="/app/userStoreAccess"
              render={() => <Redirect to="/app/userStoreAccess/table" />}
            />

            <Route
              path="/app/userStoreAccess/table"
              component={UserStoreAccessTable}
            />

            <Route
              exact
              path="/app/roleAccess"
              render={() => <Redirect to="/app/roleAccess/table" />}
            />

            <Route path="/app/roleAccess/table" component={RoleAccessTable} />

            <Route
              exact
              path="/app/userRoleAccess"
              render={() => <Redirect to="/app/userRoleAccess/table" />}
            />

            <Route
              path="/app/userRoleAccess/table"
              component={UserRoleAccessTable}
            />
            <Route
              exact
              path="/app/apiConfig"
              render={() => <Redirect to="/app/apiConfig/table" />}
            />

            <Route path="/app/apiConfig/table" component={ApiConfigTable} />

            <Route
              exact
              path="/app/acquirer"
              render={() => <Redirect to="/app/acquirer/table" />}
            />

            <Route path="/app/acquirer/table" component={AcquirerTable} />

            <Route
              exact
              path="/app/settlementConfig"
              render={() => <Redirect to="/app/settlementConfig/table" />}
            />

            <Route
              path="/app/settlementConfig/table"
              component={SettlementConfigTable}
            />

            <Route
              exact
              path="/app/acquirerStore"
              render={() => <Redirect to="/app/acquirerStore/table" />}
            />

            <Route
              path="/app/acquirerStore/table"
              component={AcquirerStoreTable}
            />

            <Route
              exact
              path="/app/store"
              render={() => <Redirect to="/app/store/table" />}
            />

            <Route path="/app/store/table" component={StoreTable} />

            <Route
              exact
              path="/app/chargeRate"
              render={() => <Redirect to="/app/chargeRate/table" />}
            />

            <Route path="/app/chargeRate/table" component={ChargeRateTable} />

            <Route
              exact
              path="/app/transaction"
              render={() => <Redirect to="/app/transaction/table" />}
            />

            <Route path="/app/transaction/table" component={TransactionTable} />

            <Route
              exact
              path="/app/terminal"
              render={() => <Redirect to="/app/terminal/table" />}
            />

            <Route path="/app/terminal/table" component={TerminalTable} />

            <Route
              exact
              path="/app/deviceToken"
              render={() => <Redirect to="/app/deviceToken/table" />}
            />

            <Route path="/app/deviceToken/table" component={DeviceTokenTable} />

            <Route
              exact
              path="/app/oneTimePassword"
              render={() => <Redirect to="/app/oneTimePassword/table" />}
            />

            <Route
              path="/app/oneTimePassword/table"
              component={OneTimePasswordTable}
            />

            <Route
              exact
              path="/app/transactionMonitorData"
              render={() => <Redirect to="/app/transactionMonitorData/table" />}
            />

            <Route path="/app/transactionMonitorData/table" component={TransactionMonitorDataTable} />

            <Route
              exact
              path="/app/refund"
              render={() => <Redirect to="/app/refund/table" />}
            />

            <Route path="/app/refund/table" component={RefundTable} />

            <Route
              exact
              path="/app/displayMessage"
              render={() => <Redirect to="/app/displayMessage/table" />}
            />

            <Route
              path="/app/displayMessage/table"
              component={DisplayMessageTable}
            />

            <Route
              exact
              path="/app/settlementRecord"
              render={() => <Redirect to="/app/settlementRecord/table" />}
            />

            <Route
              path="/app/settlementRecord/table"
              component={SettlementRecordTable}
            />

            <Route
              exact
              path="/app/settlementTimeRecord"
              render={() => <Redirect to="/app/settlementTimeRecord/table" />}
            />

            <Route
              path="/app/settlementTimeRecord/table"
              component={SettlementTimeRecordTable}
            />

            <Route
              exact
              path="/app/denormalizedSettlementTransaction"
              render={() => (
                <Redirect to="/app/denormalizedSettlementTransaction/table" />
              )}
            />

            <Route
              path="/app/denormalizedSettlementTransaction/table"
              component={DenormalizedSettlementTransactionTable}
            />

            <Route
              exact
              path="/app/businessSettlementSummary"
              render={() => (
                <Redirect to="/app/businessSettlementSummary/table" />
              )}
            />

            <Route
              path="/app/businessSettlementSummary/table"
              component={BusinessSettlementSummaryTable}
            />

            <Route
              exact
              path="/app/storeSettlementSummary"
              render={() => <Redirect to="/app/storeSettlementSummary/table" />}
            />

            <Route
              path="/app/storeSettlementSummary/table"
              component={StoreSettlementSummaryTable}
            />

            <Route
              exact
              path="/app/storeAcquirerSettlementSummary"
              render={() => (
                <Redirect to="/app/storeAcquirerSettlementSummary/table" />
              )}
            />

            <Route
              path="/app/storeAcquirerSettlementSummary/table"
              component={StoreAcquirerSettlementSummaryTable}
            />

            <Route
              exact
              path="/app/emailJob"
              render={() => <Redirect to="/app/emailJob/table" />}
            />

            <Route path="/app/emailJob/table" component={EmailJobTable} />

            <Route
              exact
              path="/app/bankJob"
              render={() => <Redirect to="/app/bankJob/table" />}
            />

            <Route path="/app/bankJob/table" component={BankJobTable} />

            <Route
              exact
              path="/app/negativeBalanceJob"
              render={() => <Redirect to="/app/negativeBalanceJob/table" />}
            />

            <Route
              path="/app/negativeBalanceJob/table"
              component={NegativeBalanceJobTable}
            />

            <Route
              exact
              path="/app/bankCode"
              render={() => <Redirect to="/app/bankCode/table" />}
            />

            <Route path="/app/bankCode/table" component={BankCode} />

            <Route
              exact
              path="/app/acquireStoreSettlementDetail"
              render={() => <Redirect to="/app/acquireStoreSettlementDetail/table" />}
            />

            <Route path="/app/acquireStoreSettlementDetail/table" component={AcquireStoreSettlementDetailTable} />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            {/* <div>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Flatlogic
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/about"}
                target={"_blank"}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/blog"}
                target={"_blank"}
                className={classes.link}
              >
                Blog
              </Link>
            </div> */}
            {/* <div>
              <Link
                href={"https://www.facebook.com/flatlogic"}
                target={"_blank"}
              >
                <IconButton aria-label="facebook">
                  <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://twitter.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div> */}
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
